import { invokePost } from '@shopware-pwa/api-client';

export function usePackStation() {
  const { apiInstance } = useShopwareContext();
  const config = ref();

  const getConfigs = async () => {
    const { data } = await apiInstance.invoke.get(
      'store-api/extend-packstation/get-config'
    );
    return (config.value = data);
  };

  const getAddress = async (data: { city: string; zipcode: string }) => {
    const response = await invokePost(
      {
        address:
          '/store-api/extend-packstation/location-finder/find-by-address',
        payload: {
          packstationCity: data.city,
          packstationZip: data.zipcode,
        },
      },
      apiInstance
    );

    return response.data;
  };

  return {
    getConfigs,
    config,
    getAddress,
  };
}
